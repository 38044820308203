$topnav-base-height: 5.625rem;

.topnav {
    .navbar-brand {
        img {
            height: auto!important;
        }
	}
}

.iti {
	display: block!important;
}

.grecaptcha-badge {
    display: none!important;
}

.landing {
    overflow-x: hidden;
}

.slider {
    .slick-prev,
    .slick-next {
        .slick-counter {
            display: none;
        }

        opacity: 0;
        -webkit-transition: all .3s ease-in-out .1s;
        -o-transition: all .3s ease-in-out .1s;
        transition: all .3s ease-in-out .1s;
    }
    .slick-prev {
        left: -20px;
    }
    .slick-next {
        right: -20px;
        opacity: 0;
        -webkit-transition: all .3s ease-in-out .1s;
        -o-transition: all .3s ease-in-out .1s;
        transition: all .3s ease-in-out .1s;
    }
    &:hover {
        .slick-prev,
        .slick-next {
            opacity: 1;
        }
        .slick-prev {
            left: 0;
        }
        .slick-next {
            right: 0;
        }
    }
	&.slick-dotted {
		margin: 0!important;
		.slick-dots {
			position: absolute;
			bottom: 15px!important;
			height: 20px;
			display: block;
			width: 100%;
			padding: 0;
			list-style: none;
			text-align: center;
			li {
				position: relative;
				display: inline-block;
				margin: 0 5px;
				width: 20px;
				height: 20px;
				// margin: 0;
				padding: 0;
				cursor: pointer;
				button {
					font-size: 0;
					line-height: 0;
					display: block;
					width: 20px;
					height: 20px;
					padding: 5px;
					cursor: pointer;
					text-indent: -9999em;
					border: 0;
					outline: none;
					background: transparent;
					&:hover,
					&:focus {
						outline: none;
						&:before {
							opacity: .5;
						}
					}
					&:before {
						content: " ";
						font-size: 10px;
						line-height: 20px;
						position: absolute;
						top: 0;
						left: 0;
						width: 20px;
						height: 5px;
						text-align: center;
						opacity: .5;
						text-indent: 0;
						background-color: #fff;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
					}
				}

				&.slick-active button:before {
					opacity: .7;
					font-weight: 600;
				}
			}
		}
	}
}

.slick-prev,
.slick-next {
    width: 64px;
    height: 64px;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 40%;
    display: block;
    padding: 0;
    cursor: pointer;
    // color: #fff;
    border: none;
    outline: none;

    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.19);
    text-align: center;
    line-height: 64px;

    // background: rgba(0, 0, 0, .25);
    opacity: 0.8;
    transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    z-index: 2;
}
.slick-prev,
.slick-next {
    &:hover,
    &:focus {
        outline: none;
    }
    &:hover {
        opacity: 1;
    }
    i.pe {
        font-size: 32px;
    }
}

.slick-prev {
    left: 0;
    border-radius: 0 3px 3px 0;
}
[dir='rtl'] .slick-prev:before {
    right: 0;
    left: auto;
}
.slick-next {
    right: 0;
    border-radius: 3px 0 0 3px;
}
[dir='rtl'] .slick-next:before {
    right: auto;
    left: 0;
}

.mini-banner-top {
	-webkit-font-smoothing: antialiased;
    background-color: $dark;
    color: #c0d1f6;
    display: block;
    padding: 24px 0;
	.mini-banner-top__content {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-between;
	}
	.mini-banner-top__copy {
		color: #c0d1f6;
		font-size: 14px;
		line-height: 1.75;
	}
	.switch-button {
		background-color: $primary;
		border-radius: 3rem;
		color: #fff;
		font-size: 14px;
		font-weight: 600;
		padding: .75rem 1rem;
		text-decoration: none;
	}
}

.uit-cookie-warning {
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	position: fixed;
	top: calc(100% - 24px);
	transform: translateY(500px);
	left: 0;
	right: 0;
	z-index: 400;
	margin: 0 10px;
	padding: 12px;
	background: $primary;
	border-radius: 12px;
	transition: transform .3s ease-in-out;
  @media (min-width:576px) {
    margin: 0 40px;
  }
  @media (min-width:992px) {
    font-size: 16px;
    width: 66%;
    max-width: 950px;
    padding: 16px 16px 16px 24px;
    margin: 0 auto;
  }
}
.uit-cookie-warning__body {
	text-align: center;
  @media (min-width:768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }
}
.uit-cookie-warning__message {
	line-height: 20px;
	margin-bottom: 8px;
  @media (min-width:768px) {
    flex-basis: 100%;
    margin-bottom: 0
  }
  @media (min-width:992px) {
    flex-basis: 60%
  }
}
.uit-cookie-warning__link {
	color: #fff;
	text-decoration: underline
}
.uit-cookie-warning__link:hover {
	color: #fff
}
.uit-cookie-warning__close {
	font-size: 14px;
	font-weight: 700;
	color: #282a32;
	white-space: nowrap;
	width: 100%;
	border-radius: 4px;
	background: #fff;
	padding: 5px 16px;
	margin: 0 auto;
	outline: none;
	border: none
}
.uit-cookie-warning__close:focus {
	outline: none
}
@media (min-width:768px) {
	.uit-cookie-warning__close {
		width: auto;
		flex-basis: 198px;
		margin: 0 0 0 20px;
		padding: 9px 16px
	}
}
.uit-cookie-warning.active {
	transform: translateY(-100%);
	transition-delay: 1s
}

$brandeis-blue: #0066ff;
$palatinate-blue: #3333cc;
$chrysler-blue: #6600cc;

.bg-gradient-animation-primary-to-secondary {
	// background-color: $primary !important;
    // background-image: linear-gradient(135deg, $primary 0%, fade-out($secondary, 0.2) 100%) !important;
	// background-image: linear-gradient(135deg, rgba(var(--bs-brandeis-blue-rgb), 1), rgba(var(--bs-palatinate-blue-rgb), 0.8), rgba(var(--bs-chrysler-blue-rgb), 1));
	// background-size: 180% 180%;
	// animation: gradient-animation 18s ease infinite;

	// background-color:hsla(0,100%,50%,1);
	// background-image:
	// radial-gradient(at 40% 20%, hsla(28,100%,74%,1) 0px, transparent 50%),
	// radial-gradient(at 80% 0%, hsla(189,100%,56%,1) 0px, transparent 50%),
	// radial-gradient(at 0% 50%, hsla(355,100%,93%,1) 0px, transparent 50%),
	// radial-gradient(at 80% 50%, hsla(340,100%,76%,1) 0px, transparent 50%),
	// radial-gradient(at 0% 100%, hsla(22,100%,77%,1) 0px, transparent 50%),
	// radial-gradient(at 80% 100%, hsla(242,100%,70%,1) 0px, transparent 50%),
	// radial-gradient(at 0% 0%, hsla(343,100%,76%,1) 0px, transparent 50%);


	background-color:hsla(270,100%,40%,1);
	background-image:
	radial-gradient(at 65% 36%, hsla(240,60%,50%,1) 0px, transparent 50%),
	radial-gradient(at 66% 69%, hsla(216,100%,50%,1) 0px, transparent 50%),
	radial-gradient(at 80% 100%, hsla(240,100%,10%,1) 0px, transparent 50%);
	background-size: 180% 180%;
	animation: gradient-animation 18s ease infinite;
}


@keyframes gradient-animation {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.text-gradient-primary-to-secondary {
	background: linear-gradient(135deg, rgba(var(--bs-brandeis-blue-rgb), 1), rgba(var(--bs-palatinate-blue-rgb), 1), rgba(var(--bs-chrysler-blue-rgb), 1));
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.wrapper-main {
	background-color: $white;
	border-bottom-left-radius: var(--bs-border-radius-2xl);
	border-bottom-right-radius: var(--bs-border-radius-2xl);
	min-height: 100vh;
}
