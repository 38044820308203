.content-terms.card-body,
.content-privacy.card-body {
	@include media-breakpoint-up(md) {
		padding: 10rem;
		h1 {
			font-size: 3rem;
			margin-bottom: 2rem;
		}
		h2 {
			margin-bottom: 0.8rem;
			font-size: 1.5rem;
		}
		p {
			font-size: 1.2rem;
		}
	}
}
